import React from 'react'
// import {
//   Form, Select, InputNumber, DatePicker, Switch, Slider, Button
// } from 'antd';
import '../styles/App.css'
import Layout from '@containers/common/Layout'
import { LocaleProvider } from 'antd'
import { BrowserRouter as Router } from 'react-router-dom'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import '@apis/interceptors'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'antd/dist/antd.less'

moment.locale('zh-cn')

export default () => {
  window.ty = {}
  return (
    <LocaleProvider locale={zh_CN}>
      <Router>
        <Layout />
      </Router>
    </LocaleProvider>
  )
}
